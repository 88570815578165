import React from 'react'
import TopNav from '../../Components/TopNav/TopNav'
import Navbar from '../../Components/Navbar/Navbar'

const Solutions = () => {
  return (
    <div>
      <TopNav/>
      <Navbar/>
    </div>
  )
}

export default Solutions